import { Button } from '@chakra-ui/react';
import {
  HeaderContainer,
  HeaderRowContainer,
  HeaderTitle,
  ScreenContainer,
  SectionCard,
  SectionContainer,
  TextInput,
  useDebounceValue,
} from '@maestro/components';
import { ImageFallback } from '@maestro/components/ImageFallback';
import { dimensions, rawDimensions, textStyles } from '@maestro/styles';
import { useNavigation } from '@refinedev/core';
import { useState } from 'react';
import styled from 'styled-components';
import { EmptyState } from '../../components/EmptyState';
import { TableLoader } from '../../components/TableLoader';
import { useIsAdminUser } from '../../useIsAdminUser';
import { useListSeries } from './hooks/useListSeries';

export const SeriesList = () => {
  const [searchValue, setSearch] = useState('');
  const search = useDebounceValue(searchValue, 500);
  const isAdminUser = useIsAdminUser();

  const { series, isLoading, fetchMore, hasMore } = useListSeries({
    listAll: isAdminUser,
    pauseFetch: isAdminUser === undefined,
    search,
  });
  const navigation = useNavigation();

  return (
    <ScreenContainer>
      <HeaderContainer>
        <HeaderRowContainer>
          <HeaderTitle>Series</HeaderTitle>
        </HeaderRowContainer>
        <TextInput
          placeholder="Search series"
          maxCharacters={25}
          enforceMaxCharacters
          value={searchValue}
          showRemoveButton
          onRemove={() => setSearch('')}
          onChange={(event) => setSearch(event.target.value)}
        />
      </HeaderContainer>

      <SectionContainer>
        <SectionCard>
          {isLoading && series.length === 0 ? (
            <TableLoader />
          ) : series.length > 0 ? (
            <SeriesListContainer>
              {series.map((item, index) => (
                <div key={item.id}>
                  <SeriesContainer>
                    {item.coverUrl ? (
                      <SeriesImage src={item.coverUrl} />
                    ) : (
                      <ImageFallback
                        width={rawDimensions.size80}
                        height={rawDimensions.size80}
                        iconSize={rawDimensions.size16}
                      />
                    )}
                    <SeriesDetails>
                      {item.genre && (
                        <SeriesCategory>{item.genre}</SeriesCategory>
                      )}
                      <SeriesName>{item.title}</SeriesName>
                      <SeriesDescription>{item.description}</SeriesDescription>
                    </SeriesDetails>
                    <Button
                      variant="default"
                      onClick={() => navigation.push(`/series/${item.id}`)}
                    >
                      View
                    </Button>
                  </SeriesContainer>
                  {index < series.length - 1 && <Divider />}
                </div>
              ))}
            </SeriesListContainer>
          ) : (
            <EmptyState title="No series found." />
          )}
        </SectionCard>
        {hasMore && (
          <LoadMoreContainer>
            <Button variant="default" onClick={fetchMore} isLoading={isLoading}>
              Load more
            </Button>
          </LoadMoreContainer>
        )}
      </SectionContainer>
    </ScreenContainer>
  );
};

const SeriesListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SeriesContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size16};
  align-items: center;
  padding: ${dimensions.size8};
`;

const SeriesImage = styled.img`
  width: ${dimensions.size80};
  height: ${dimensions.size80};
  object-fit: cover;
  border-radius: ${dimensions.size8};
`;

const SeriesDetails = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${dimensions.size8};
  align-items: start;
  justify-content: center;
`;

const SeriesCategory = styled.div`
  ${textStyles.label.lb12sb}
  padding: ${dimensions.size4} ${dimensions.size8};
  color: ${({ theme }) => theme.colors.text.header};
  background: ${({ theme }) => theme.colors.background.shade};
  border-radius: ${dimensions.size4};
`;

const SeriesName = styled.div`
  ${textStyles.label.lb16sb}
  color: ${({ theme }) => theme.colors.text.header};
`;

const SeriesDescription = styled.div`
  ${textStyles.body.b14m}
  display: -webkit-box;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.text.body};
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`;

const Divider = styled.div`
  width: 100%;
  height: ${dimensions.size1};
  margin: ${dimensions.size24} 0;
  background: ${({ theme }) => theme.colors.border.default[100]};
`;

const LoadMoreContainer = styled.div`
  display: flex;
  justify-content: center;
`;
