import { StudioNodeType, type CoinTossNodeData } from '@common/studio-types';
import type { ReturnedValue } from '../actions/getValue/types';
import { getValue } from '../actions/utils';
import { getCoinTossSuccessRate } from '../coinToss/getCoinTossSuccessRate';
import { CoinTossChoice, CoinTossSide } from '../game';
import type { CoinTossResultMessage, Message } from '../game/messages.types';
import type { StatefulGame } from './statefulGame';

type Args = {
  interactionId: string;
  choice: CoinTossChoice;
};

const flipCoin = (successRate: number, choice: CoinTossSide): CoinTossSide => {
  const otherSide =
    choice === CoinTossSide.Heads ? CoinTossSide.Tails : CoinTossSide.Heads;
  const success = Math.random() <= successRate ? true : false;

  return success ? choice : otherSide;
};

export const handleCoinTossInteraction = async (
  game: StatefulGame,
  interactionArgs: Args,
): Promise<Message[]> => {
  const { interactionId, choice } = interactionArgs;
  const { playerData } = game.gameData;
  const interaction = game.gameNodes.nodeById<CoinTossNodeData>(interactionId);

  if (
    !interaction ||
    game.state().currentInteraction !== interactionId ||
    interaction.type !== StudioNodeType.CoinToss
  ) {
    return [game.errorMessage('Invalid interaction')];
  }

  let successRate = 0.5;
  let value: ReturnedValue<number> | undefined;

  if (interaction.property) {
    value = await getValue<number>(
      interaction.property,
      game.state(),
      game.config,
      playerData,
    );
    successRate = await getCoinTossSuccessRate(value.value);
  }

  const displayableSuccessRate = Math.ceil(successRate * 100);

  if (choice === CoinTossChoice.ConfirmFail) {
    const { coinTossData } = game.state();

    if (!coinTossData) {
      return [game.errorMessage('No coin toss data')];
    }

    /* This needs to run BEFORE the game execution, otherwise
     * it'll override the interaction that will be set during execution
     */
    game.setGameState({
      currentInteraction: undefined,
      coinTossData: undefined,
    });

    const messages = await game.execute(interaction.failNodeId);
    const resultMessage: Message = {
      nodeId: interactionId,
      id: interactionId,
      type: 'coin-toss-result',
      successful: false,
      choice: coinTossData.choice,
      attribute: value && { name: value.label, description: '' },
      confirmed: true,
      result:
        coinTossData.choice === CoinTossSide.Heads
          ? CoinTossSide.Tails
          : CoinTossSide.Heads,
      successRate: displayableSuccessRate,
    };

    if (messages.length === 0) {
      // setting back to the current interaction
      game.setGameState({
        currentInteraction: interaction.id,
        coinTossData,
      });

      return [resultMessage];
    }

    return [resultMessage, ...messages];
  }

  const choiceSide =
    choice === CoinTossChoice.Heads ? CoinTossSide.Heads : CoinTossSide.Tails;
  const result = flipCoin(successRate, choiceSide);
  const message: CoinTossResultMessage = {
    nodeId: interactionId,
    id: interactionId,
    type: 'coin-toss-result',
    confirmed: result === choiceSide,
    successful: result === choiceSide,
    choice: choiceSide,
    attribute: value && { name: value.label, description: '' },
    result,
    successRate: displayableSuccessRate,
  };

  if (result === choiceSide) {
    /* This needs to run BEFORE the game execution, otherwise
     * it'll override the interaction that will be set during execution
     */
    game.setGameState({
      currentInteraction: undefined,
      coinTossData: undefined,
    });

    const messages = await game.execute(interaction.successNodeId);

    if (messages.length === 0) {
      // setting back to the current interaction
      game.setGameState({ currentInteraction: interaction.id });

      return [message];
    }

    return [message, ...messages];
  } else {
    game.setGameState({ coinTossData: { choice: choiceSide, failed: true } });

    return [message];
  }
};
