import { Button, Tooltip } from '@chakra-ui/react';
import { StudioNodeData } from '@common/studio-types';
import { Icon } from '@maestro/components';
import { dimensions } from '@maestro/styles';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { shouldShowGenerateWithAi } from '../hooks/useGenerateNodeWithAi';
import { nodeConfigs } from '../nodes';
import { Drawer } from './Drawer';
import { GenerateNodeWithAi } from './GenerateNodeWithAi';
import { NodeForm } from './NodeForm';

type Props = {
  isOpen: boolean;
  studioNodeData?: StudioNodeData;
  onStudioNodeDataChange: (studioNode: StudioNodeData) => void;
  onCancel: VoidFunction;
};

export const StudioDrawer: React.FC<Props> = (props) => {
  const [isAiOpen, setIsAiOpen] = useState(false);
  const title = props.studioNodeData
    ? nodeConfigs[props.studioNodeData.type]?.name
    : '';

  const toggleAi = () => setIsAiOpen(!isAiOpen);
  const showAiButton =
    props.studioNodeData &&
    shouldShowGenerateWithAi(props.studioNodeData?.type);

  useEffect(() => setIsAiOpen(false), [props.studioNodeData]);

  return (
    <Drawer
      ariaLabel="Node Settings"
      isOpen={props.isOpen && !!title}
      onCancel={props.onCancel}
      title={title}
      headerContent={
        <>
          {showAiButton && (
            <Tooltip
              label="Generate with AI"
              aria-label="Generate with AI"
              placement="bottom-end"
            >
              <Button
                variant="default"
                size="sm"
                onClick={toggleAi}
                isActive={isAiOpen}
              >
                <Icon name="edit-with-ai" size={16} />
              </Button>
            </Tooltip>
          )}
        </>
      }
    >
      {props.studioNodeData && (
        <Container>
          {isAiOpen && (
            <GenerateNodeWithAi
              studioNodeData={props.studioNodeData}
              onStudioNodeDataChange={props.onStudioNodeDataChange}
              onFinishGeneration={() => setIsAiOpen(false)}
            />
          )}
          <NodeForm
            studioNodeData={props.studioNodeData}
            onStudioNodeDataChange={props.onStudioNodeDataChange}
          />
        </Container>
      )}
    </Drawer>
  );
};

const Container = styled.div`
  padding: ${dimensions.size16};
`;
