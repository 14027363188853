import { StatBlockLayout, StatBlockSectionLayout } from '@common/studio-types';
import { dimensions, rawDimensions, textStyles } from '@maestro/styles';
import React, { memo } from 'react';
import styled from 'styled-components';
import { StatBlockMessage } from '@enigma-engine/core';
import { messageAnimation } from './keyframes';

type Props = {
  message: StatBlockMessage;
};

const Component: React.FC<Props> = ({ message }) => {
  const { data } = message;

  return (
    <StatBlockNodeContainer>
      <HeaderContainer>
        {data.layout === StatBlockLayout.FullWidth && data.image && (
          <Image src={data.image.url} width={'100%'} />
        )}
        <HeaderTitleContainer>
          {data.layout !== StatBlockLayout.FullWidth && data.image && (
            <CompactImage src={data.image.url} />
          )}
          <ColumnContainer>
            <StatBlockTitle>{data.name}</StatBlockTitle>
            {data.subtitle && (
              <StatBlockSubtitle>{data.subtitle}</StatBlockSubtitle>
            )}
          </ColumnContainer>
        </HeaderTitleContainer>
        {data.description && (
          <StatBlockDescription>{data.description}</StatBlockDescription>
        )}
      </HeaderContainer>

      {data.sections.length > 0 && (
        <StatBlockSectionsContainer>
          {data.sections.map((section, index) => (
            <StatBlockSection key={index}>
              {section.title && (
                <StatBlockSectionTitle>{section.title}</StatBlockSectionTitle>
              )}
              {section.layout === StatBlockSectionLayout.GridList && (
                <StatBlockGridListSectionContent>
                  {section.fields.map((field, index) => (
                    <StatBlockGridListItem key={index}>
                      <StatBlockGridListItemTitle>
                        {field.name}
                      </StatBlockGridListItemTitle>
                      <StatBlockGridListItemValue>
                        {field.value}
                      </StatBlockGridListItemValue>
                    </StatBlockGridListItem>
                  ))}
                </StatBlockGridListSectionContent>
              )}
              {section.layout === StatBlockSectionLayout.Description && (
                <StatBlockDescriptionSectionContent>
                  {section.fields.map((field, index) => (
                    <StatBlockDescriptionItem key={index}>
                      {field.name && (
                        <StatBlockDescriptionItemTitle>
                          {field.name}
                        </StatBlockDescriptionItemTitle>
                      )}
                      <StatBlockDescriptionItemValue>
                        {field.value}
                      </StatBlockDescriptionItemValue>
                    </StatBlockDescriptionItem>
                  ))}
                </StatBlockDescriptionSectionContent>
              )}
            </StatBlockSection>
          ))}
        </StatBlockSectionsContainer>
      )}
    </StatBlockNodeContainer>
  );
};

export const MaestroGameMessageStatBlock = memo(Component);

const StatBlockNodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.background.shade};
  border-radius: ${dimensions.size8};
  animation: ${messageAnimation} 0.5s ease-in-out forwards;
`;

const Image = styled.img`
  object-fit: cover;
  border-radius: ${dimensions.size8};
`;

const CompactImage = styled.img`
  width: ${rawDimensions.size96 + rawDimensions.size4}px;
  height: ${rawDimensions.size96 + rawDimensions.size4}px;
  object-fit: cover;
  border-radius: ${dimensions.size8};
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size8};
  padding: ${dimensions.size16};
`;

const HeaderTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size16};
  align-items: center;
  margin-bottom: ${dimensions.size8};
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size4};
`;

const StatBlockTitle = styled.div`
  ${textStyles.title.t20b}
  flex: 1;
  color: ${({ theme }) => theme.colors.text.header};
`;

const StatBlockSubtitle = styled.div`
  ${textStyles.body.b14sb}
  flex: 1;
  color: ${({ theme }) => theme.colors.text.body};
`;

const StatBlockDescription = styled.div`
  ${textStyles.body.b14m}
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size4};
  align-items: center;
  color: ${({ theme }) => theme.colors.text.body};
  white-space: pre-line;
`;

const StatBlockSectionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size16};
  padding: ${dimensions.size16};
  border-top: 1px solid ${({ theme }) => theme.colors.base.light[100]};
`;

const StatBlockSectionTitle = styled.div`
  ${textStyles.label.lb14sb}
  margin-bottom: ${dimensions.size8};
  color: ${({ theme }) => theme.colors.text.header};
`;

const StatBlockSection = styled.div`
  display: block;
`;

const StatBlockGridListSectionContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-around;
`;

const StatBlockGridListItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size4};
  align-items: center;
`;

const StatBlockGridListItemTitle = styled.div`
  ${textStyles.body.b14m}
  color: ${({ theme }) => theme.colors.text.body};
  text-align: center;
`;

const StatBlockGridListItemValue = styled.div`
  ${textStyles.body.b14sb}
  color: ${({ theme }) => theme.colors.text.header};
`;

const StatBlockDescriptionSectionContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size8};
  align-items: start;
  justify-content: space-between;
`;

const StatBlockDescriptionItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size4};
  width: 100%;
  padding: ${dimensions.size12} ${dimensions.size20};
  background: ${({ theme }) => theme.colors.background.shade};
  border-radius: ${dimensions.size8};
`;

const StatBlockDescriptionItemTitle = styled.div`
  ${textStyles.body.b14sb}
  color: ${({ theme }) => theme.colors.text.header};
`;

const StatBlockDescriptionItemValue = styled.div`
  ${textStyles.body.b14m}
  color: ${({ theme }) => theme.colors.text.body};
`;
