import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { Icon } from '@maestro/components';
import { ModalCloseButton } from '@maestro/components/ModalCloseButton';
import { dimensions, rawDimensions, textStyles } from '@maestro/styles';
import React from 'react';
import styled from 'styled-components';

type Props = {
  episodeCoverUrl: string;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  operation: string;
  description: React.ReactNode;
  isEdit?: boolean;
  isLoading?: boolean;
  additionalContent?: React.ReactNode;
};

export const EpisodeModal: React.FC<Props> = (props) => {
  const { episodeCoverUrl, description, operation, title, isEdit } = props;

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      size="3xl"
      variant={'fromCta'}
      closeOnEsc={!props?.isLoading}
      closeOnOverlayClick={!props?.isLoading}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <span>{title}</span>
          <ModalCloseButton
            disabled={!!props?.isLoading}
            onClose={props.onClose}
          />
        </ModalHeader>
        <ModalBody>
          <Container>
            <EpisodeImage src={episodeCoverUrl} />
            <TextContainer>
              <ModalTitle>{operation}</ModalTitle>
              <ModalDescription>{description}</ModalDescription>
              {props?.additionalContent ? (
                <AdditionalContentContainer>
                  {props.additionalContent}
                </AdditionalContentContainer>
              ) : null}
            </TextContainer>
          </Container>
        </ModalBody>

        <ModalFooter>
          <Button
            variant="ghost"
            mr={3}
            onClick={props.onClose}
            isDisabled={!!props?.isLoading}
          >
            Nevermind
          </Button>
          <StyledPublishButton
            variant="primary"
            onClick={props.onConfirm}
            isLoading={!!props?.isLoading}
            isDisabled={!!props?.isLoading}
          >
            {isEdit ? (
              <>Continue</>
            ) : (
              <>
                <Icon name="publish" size={rawDimensions.size16} />
                Publish episode
              </>
            )}
          </StyledPublishButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const StyledPublishButton = styled(Button)`
  display: flex;
  flex-flow: row;
  gap: ${dimensions.size8};
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size48};
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size12};
`;

const EpisodeImage = styled.img`
  min-width: ${dimensions.size160};
  height: auto;
  max-height: ${dimensions.size320};
  margin: auto;
  object-fit: cover;
  border: ${dimensions.size1} solid
    ${({ theme }) => theme.colors.border.default[100]};
  border-radius: ${dimensions.size8};
`;

const ModalTitle = styled.div`
  ${textStyles.title.t24b}
  color: ${({ theme }) => theme.colors.text.header};
  text-align: center;
`;

const ModalDescription = styled.div`
  ${textStyles.body.b16m}
  color: ${({ theme }) => theme.colors.text.body};
  text-align: center;

  & strong {
    ${textStyles.body.b16sb}
    display: inline;
    color: ${({ theme }) => theme.colors.text.header};
  }
`;

const AdditionalContentContainer = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  margin-top: ${dimensions.size36};
`;
