import { dimensions, textStyles } from '@maestro/styles';
import styled from 'styled-components';
import { useItem } from '../../hooks/useItem';

type Props = { itemId: string };

export const ItemContainer: React.FC<Props> = ({ itemId }) => {
  const { item } = useItem(itemId);

  if (!item) return <ItemSkeleton />;

  return (
    <SelectedItem>
      <img src={item.imagePath} alt={item.name} />
      <div>
        <SelectedItemName>{item.name}</SelectedItemName>
      </div>
    </SelectedItem>
  );
};

const ItemSkeleton = () => {
  return (
    <SelectedItem>
      <ItemImage />
      <ItemNameContainerSkeleton>
        <ItemNameSkeleton />
      </ItemNameContainerSkeleton>
    </SelectedItem>
  );
};

const SelectedItem = styled.div`
  display: flex;
  flex-flow: row;
  gap: ${dimensions.size12};
  align-items: center;
  width: 100%;
  padding: ${dimensions.size12};
  background: ${({ theme }) => theme.colors.background.shade};
  border-radius: ${dimensions.size4};

  img {
    width: ${dimensions.size24};
    height: ${dimensions.size24};
    object-fit: cover;
    border-radius: ${dimensions.size8};
  }
`;

const ItemImage = styled.div`
  width: ${dimensions.size24};
  min-width: ${dimensions.size24};
  max-width: ${dimensions.size24};
  height: ${dimensions.size24};
  background: ${({ theme }) => theme.colors.background.shade};
  border-radius: ${dimensions.size8};
`;

const ItemNameSkeleton = styled.div`
  width: 60%;
  height: ${dimensions.size16};
  background: ${({ theme }) => theme.colors.base.light[100]};
  border-radius: ${dimensions.size4};
`;

const ItemNameContainerSkeleton = styled.div`
  display: flex;
  flex-flow: column;
  gap: ${dimensions.size10};
  width: 100%;
`;

const SelectedItemName = styled.div`
  ${textStyles.body.b14sb}
  display: -webkit-box;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.text.header};
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-align: left;
`;
