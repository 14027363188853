import { dimensions, textStyles } from '@maestro/styles';
import React, { memo } from 'react';
import styled from 'styled-components';
import { InventoryItemChangeMessage } from '@enigma-engine/core';
import { useItem } from '../../../hooks/useItem';
import { messageAnimation } from './keyframes';

type Props = {
  message: InventoryItemChangeMessage;
};

const Component: React.FC<Props> = ({ message }) => {
  const { item, isLoading } = useItem(message.itemId);

  if (isLoading) return;

  return (
    <Container>
      <Image src={item?.imagePath} />

      <ItemDescriptionContainer>
        <ItemName>{item?.name ?? ''}</ItemName>
        <ItemDescription>{item?.category ?? ''}</ItemDescription>
      </ItemDescriptionContainer>

      <ItemDelta>
        {message.delta > 0
          ? `+ ${message.delta}`
          : `- ${Math.abs(message.delta)}`}
      </ItemDelta>
    </Container>
  );
};

export const MaestroGameMessageInventoryItemChange = memo(Component);

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size8};
  align-items: center;
  padding: ${dimensions.size16};
  color: ${({ theme }) => theme.colors.text.header};
  white-space: pre-line;
  background-color: ${({ theme }) => theme.colors.background.shade};
  border-radius: ${dimensions.size8};
  animation: ${messageAnimation} 0.5s ease-in-out forwards;
`;

const Image = styled.img`
  width: ${dimensions.size48};
  height: ${dimensions.size48};
  background-color: ${({ theme }) => theme.colors.background.shade};
  border-radius: ${dimensions.size8};
`;

const ItemDescriptionContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${dimensions.size2};
`;

const ItemName = styled.div`
  ${textStyles.body.b16sb}
  color: ${({ theme }) => theme.colors.text.header};
`;

const ItemDescription = styled.div`
  ${textStyles.body.b14sb}
  color: ${({ theme }) => theme.colors.text.placeholder};
`;

const ItemDelta = styled.div`
  ${textStyles.body.b16sb}
  color: ${({ theme }) => theme.colors.text.accent};
`;
