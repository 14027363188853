/**
 * Attribution: This file was taken from React Flow Pro examples.
 * It wasn't created by Tally Labs although changes were made.
 *
 * @see https://pro.reactflow.dev/examples/react/copy-paste
 */
import { StudioNodeData } from '@common/studio-types';
import { useCallback, useEffect, useRef } from 'react';
import {
  Node,
  useReactFlow,
  getConnectedEdges,
  Edge,
  XYPosition,
  useStore,
} from 'reactflow';
import { nodeConfigs } from '../../nodes';
import { toNodeId } from '../../toId';
import { useShortcut } from '../useShortcut';
import {
  getSavedEdges,
  getSavedNodes,
  saveEdges,
  saveNodes,
} from './copyPasteUtils';

export const useCopyPaste = () => {
  const mousePosRef = useRef<XYPosition>({ x: 0, y: 0 });
  const rfDomNode = useStore((state) => state.domNode);

  const { getNodes, setNodes, getEdges, setEdges, screenToFlowPosition } =
    useReactFlow<StudioNodeData, Edge>();

  // Set up the paste buffers to store the copied nodes and edges.

  // initialize the copy/paste hook
  // 1. remove native copy/paste/cut handlers
  // 2. add mouse move handler to keep track of the current mouse position
  useEffect(() => {
    const events = ['cut', 'copy', 'paste'];

    if (rfDomNode) {
      const preventDefault = (e: Event) => e.preventDefault();

      const onMouseMove = (event: MouseEvent) => {
        mousePosRef.current = { x: event.clientX, y: event.clientY };
      };

      for (const event of events) {
        rfDomNode.addEventListener(event, preventDefault);
      }

      rfDomNode.addEventListener('mousemove', onMouseMove);

      return () => {
        for (const event of events) {
          rfDomNode.removeEventListener(event, preventDefault);
        }

        rfDomNode.removeEventListener('mousemove', onMouseMove);
      };
    }
  }, [rfDomNode]);

  const copy = useCallback(() => {
    const selectedNodes = getNodes().filter((node) => {
      const nodeConfig = nodeConfigs[node.data.type];

      return node.selected && !!nodeConfig;
    });
    const selectedEdges = getConnectedEdges(selectedNodes, getEdges()).filter(
      (edge) => {
        const isExternalSource = selectedNodes.every(
          (n) => n.id !== edge.source,
        );
        const isExternalTarget = selectedNodes.every(
          (n) => n.id !== edge.target,
        );

        return !(isExternalSource || isExternalTarget);
      },
    );

    saveNodes(selectedNodes);
    saveEdges(selectedEdges);
  }, [getNodes, getEdges]);

  const cut = useCallback(() => {
    const selectedNodes = getNodes().filter(
      (node) => node.selected && !!nodeConfigs[node.data.type],
    );
    const selectedEdges = getConnectedEdges(selectedNodes, getEdges()).filter(
      (edge) => {
        const isExternalSource = selectedNodes.every(
          (n) => n.id !== edge.source,
        );
        const isExternalTarget = selectedNodes.every(
          (n) => n.id !== edge.target,
        );

        return !(isExternalSource || isExternalTarget);
      },
    );

    saveNodes(selectedNodes);
    saveEdges(selectedEdges);

    // A cut action needs to remove the copied nodes and edges from the graph.
    setNodes((nodes) =>
      nodes.filter((node) => !(node.selected && !!nodeConfigs[node.data.type])),
    );
    setEdges((edges) => edges.filter((edge) => !selectedEdges.includes(edge)));
  }, [getNodes, setNodes, getEdges, setEdges]);

  const paste = useCallback(
    (
      { x: pasteX, y: pasteY } = screenToFlowPosition({
        x: mousePosRef.current.x,
        y: mousePosRef.current.y,
      }),
    ) => {
      const bufferedNodes = getSavedNodes();
      const bufferedEdges = getSavedEdges();

      if (!bufferedNodes.length) {
        return;
      }

      const minX = Math.min(...bufferedNodes.map((s) => s.position.x));
      const minY = Math.min(...bufferedNodes.map((s) => s.position.y));

      const mappedEdges: Record<string, string> = {};

      const newNodes: Node<StudioNodeData>[] = bufferedNodes.map(
        (node): Node<StudioNodeData> => {
          const x = pasteX + (node.position.x - minX);
          const y = pasteY + (node.position.y - minY);

          const { type, cloneNodeData } = nodeConfigs[node.data.type];
          const clone = cloneNodeData(node.data);
          const nodeData = { ...clone.data, type } as StudioNodeData;
          const id = clone.data.id;

          mappedEdges[node.id] = id;

          clone.connections?.forEach(({ oldId, newId }) => {
            mappedEdges[oldId] = newId;
          });

          return { ...node, id, data: nodeData, position: { x, y } };
        },
      );

      const newEdges: Edge[] = bufferedEdges.map((edge) => {
        const id = toNodeId();
        const source = mappedEdges[edge.source];
        const target = mappedEdges[edge.target];
        const sourceHandle = edge.sourceHandle
          ? mappedEdges[edge.sourceHandle]
          : undefined;

        return { ...edge, id, source, target, sourceHandle };
      });

      setNodes((nodes) => [
        ...nodes.map((node) => ({ ...node, selected: false })),
        ...newNodes,
      ]);
      setEdges((edges) => [
        ...edges.map((edge) => ({ ...edge, selected: false })),
        ...newEdges,
      ]);
    },
    [screenToFlowPosition, setNodes, setEdges],
  );

  useShortcut(['Meta+x', 'Control+x'], cut);
  useShortcut(['Meta+c', 'Control+c'], copy);
  useShortcut(['Meta+v', 'Control+v'], paste);

  return { cut, copy, paste };
};
