import { Button, useDisclosure } from '@chakra-ui/react';
import {
  Icon,
  Section,
  SectionCard,
  SectionTitle,
  TextInput,
} from '@maestro/components';
import { useItems, CreateItemModal, EditItemModal } from '@maestro/studio';
import { Item } from '@maestro/studio/hooks/useItems';
import { dimensions, rawDimensions, textStyles } from '@maestro/styles';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { EmptyState } from '../../components/EmptyState';
import { TableLoader } from '../../components/TableLoader';
import { useIsAdminUser } from '../../useIsAdminUser';

type Props = {
  seriesId: string;
};

export const ItemsList: React.FC<Props> = ({ seriesId }) => {
  const [search, setSearch] = useState('');
  const isAdminUser = useIsAdminUser();
  const createOpen = useDisclosure();
  const [selectedItemId, setSelectedItemId] = useState('');

  const { items, isLoading, refetch } = useItems({
    seriesId,
    userCreatedOnly: !isAdminUser,
    pauseFetch: isAdminUser === undefined,
  });

  const filterItems = useCallback(
    (item: Item) => {
      const lowerSearch = search.toLowerCase();

      return (
        item.name.toLowerCase().includes(lowerSearch) ||
        item.description.toLowerCase().includes(lowerSearch)
      );
    },
    [items, search],
  );

  const filteredItems = useMemo(() => items.filter(filterItems), [filterItems]);

  const createItems = () => {
    createOpen.onOpen();
  };

  return (
    <Section>
      <CreateItemModal
        seriesId={seriesId}
        isOpen={createOpen.isOpen}
        onClose={createOpen.onClose}
        onCreated={refetch}
      />
      <EditItemModal
        seriesId={seriesId}
        itemId={selectedItemId}
        onClose={() => setSelectedItemId('')}
        onUpdated={refetch}
      />
      <SectionTitle>
        Items
        <Button
          onClick={createItems}
          variant="ghost"
          leftIcon={<Icon name="plus" size={rawDimensions.size16} />}
        >
          New item
        </Button>
      </SectionTitle>
      <TextInput
        placeholder="Search items"
        maxCharacters={25}
        enforceMaxCharacters
        value={search}
        onChange={(event) => setSearch(event.target.value)}
      />

      <SectionCard>
        {isLoading ? (
          <TableLoader />
        ) : filteredItems.length > 0 ? (
          <ItemListContainer>
            {filteredItems.map((item, index) => (
              <div key={item.id}>
                <ItemContainer>
                  <ItemImage src={item.imagePath} />
                  <ItemDetails>
                    <ItemCategory>{item.category}</ItemCategory>
                    <ItemName>{item.name}</ItemName>
                    <ItemDescription>{item.description}</ItemDescription>
                  </ItemDetails>
                  <Button
                    variant="default"
                    onClick={() => setSelectedItemId(item.id)}
                  >
                    Edit
                  </Button>
                </ItemContainer>
                {index < filteredItems.length - 1 && <Divider />}
              </div>
            ))}
          </ItemListContainer>
        ) : (
          <EmptyState
            title={
              items.length
                ? 'No items found.'
                : "You haven't created any items yet."
            }
            button={
              items.length
                ? undefined
                : { label: 'Create item', onClick: createItems }
            }
          />
        )}
      </SectionCard>
      <ItemListFooter>
        {items.length > 0 && (
          <Button
            onClick={createItems}
            variant="ghost"
            leftIcon={<Icon name="plus" size={rawDimensions.size16} />}
          >
            New item
          </Button>
        )}
      </ItemListFooter>
    </Section>
  );
};

const ItemListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size16};
  align-items: center;
  padding: ${dimensions.size8};
`;

const ItemImage = styled.img`
  width: ${dimensions.size80};
  height: ${dimensions.size80};
  object-fit: cover;
  border-radius: ${dimensions.size8};
`;

const ItemDetails = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${dimensions.size8};
  align-items: start;
  justify-content: center;
`;

const ItemCategory = styled.div`
  ${textStyles.label.lb12sb}
  padding: ${dimensions.size4} ${dimensions.size8};
  color: ${({ theme }) => theme.colors.text.header};
  background: ${({ theme }) => theme.colors.background.shade};
  border-radius: ${dimensions.size4};
`;

const ItemName = styled.div`
  ${textStyles.label.lb16sb}
  color: ${({ theme }) => theme.colors.text.header};
`;

const ItemDescription = styled.div`
  ${textStyles.body.b14m}
  display: -webkit-box;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.text.body};
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`;

const Divider = styled.div`
  width: 100%;
  height: ${dimensions.size1};
  margin: ${dimensions.size24} 0;
  background: ${({ theme }) => theme.colors.border.default[100]};
`;

const ItemListFooter = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
