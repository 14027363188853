import { dimensions } from '@maestro/styles';
import React, { memo } from 'react';
import styled from 'styled-components';
import { ImageMessage } from '@enigma-engine/core';
import { messageAnimation } from './keyframes';

type Props = {
  message: ImageMessage;
};

const Component: React.FC<Props> = ({ message }) => {
  return <Image src={message.imageUrl} />;
};

export const MaestroGameMessageImage = memo(Component);

const Image = styled.img`
  width: 100%;
  animation: ${messageAnimation} 0.5s ease-in-out forwards;
  border-radius: ${dimensions.size8};
`;
