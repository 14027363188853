import { StudioNodeData, StudioNodeType } from '@common/studio-types';
import { NodeProps } from 'reactflow';
import { changeValueNode } from './changeValue';
import { characterCreationNode } from './characterCreation';
import { characterCreatorCompleteNode } from './characterCreatorComplete';
import { coinTossNode } from './coinToss';
import { commentNode } from './comment';
import { conditionCheckNode } from './conditionCheck';
import { diceRollNode } from './diceRoll';
import { endEpisodeNode } from './endEpisode';
import { episodeSetupNode } from './episodeSetup';
import { getValueNode } from './getValue';
import { imageNode } from './image';
import { narratorTextNode } from './narratorText';
import { NodeConfig, StudioNodeComponent } from './Node.types';
import { ObjectiveCompleteNode } from './objectiveComplete/ObjectiveCompleteNode';
import { onboardingCompleteNode } from './onboardingComplete';
import { playerInputNode } from './playerInput';
import { singleSelectNode } from './singleSelect';
import { StartNode } from './start/StartNode';
import { statBlockNode } from './statBlock';

export const nodeConfigList: NodeConfig<StudioNodeType, StudioNodeData>[] = [
  narratorTextNode as NodeConfig<StudioNodeType, StudioNodeData>,
  singleSelectNode as NodeConfig<StudioNodeType, StudioNodeData>,
  imageNode as NodeConfig<StudioNodeType, StudioNodeData>,
  commentNode as NodeConfig<StudioNodeType, StudioNodeData>,
  endEpisodeNode as NodeConfig<StudioNodeType, StudioNodeData>,
  characterCreationNode as NodeConfig<StudioNodeType, StudioNodeData>,
  onboardingCompleteNode as NodeConfig<StudioNodeType, StudioNodeData>,
  characterCreatorCompleteNode as NodeConfig<StudioNodeType, StudioNodeData>,
  episodeSetupNode as NodeConfig<StudioNodeType, StudioNodeData>,
  getValueNode as NodeConfig<StudioNodeType, StudioNodeData>,
  changeValueNode as NodeConfig<StudioNodeType, StudioNodeData>,
  conditionCheckNode as NodeConfig<StudioNodeType, StudioNodeData>,
  statBlockNode as NodeConfig<StudioNodeType, StudioNodeData>,
  diceRollNode as NodeConfig<StudioNodeType, StudioNodeData>,
  playerInputNode as NodeConfig<StudioNodeType, StudioNodeData>,
  coinTossNode as NodeConfig<StudioNodeType, StudioNodeData>,
];

export const nodeConfigs = nodeConfigList.reduce(
  (acc, node) => ({ ...acc, [node.type]: node }),
  {} as Record<StudioNodeType, NodeConfig<StudioNodeType, StudioNodeData>>,
);

export const studioNodeTypes: Record<
  StudioNodeType,
  StudioNodeComponent<NodeProps>
> = {
  ...nodeConfigList.reduce(
    (acc, config) => ({ ...acc, [config.type]: config.node }),
    {} as Record<StudioNodeType, StudioNodeComponent<NodeProps>>,
  ),
  [StudioNodeType.Start]: StartNode as StudioNodeComponent<NodeProps>,
  [StudioNodeType.ObjectiveComplete]:
    ObjectiveCompleteNode as unknown as StudioNodeComponent<NodeProps>,
};
