import { Icon } from '@maestro/components';
import { dimensions, rawDimensions, textStyles } from '@maestro/styles';
import React from 'react';
import styled from 'styled-components';
import { choicesAnimation } from './keyframes';

type Props = {
  prompt: string;
  options: { id: string; text: string; icon?: string }[];
  onSelect?(optionId: string): void;
  showChoices: boolean;
  isAnimationDisabled?: boolean;
};

export const MaestroPlayerChoiceInteraction: React.FC<Props> = (props) => {
  const { prompt, options, onSelect, showChoices } = props;

  return (
    <>
      <Prompt $animationDisabled={props.isAnimationDisabled}>{prompt}</Prompt>

      {showChoices && (
        <ChoicesContainer>
          <SelectOption>Select option</SelectOption>
          {options.map((option) => (
            <ChoiceContainer
              key={option.id}
              onClick={() => onSelect?.(option.id)}
            >
              <div>{option.text}</div>
              {option.icon && (
                <StyledIcon name={option.icon} size={rawDimensions.size20} />
              )}
            </ChoiceContainer>
          ))}
        </ChoicesContainer>
      )}
    </>
  );
};

const Prompt = styled.div<{ $animationDisabled?: boolean }>`
  ${textStyles.body.b16sb};
  color: ${({ theme }) => theme.colors.text.header};
  ${({ $animationDisabled }) =>
    !$animationDisabled &&
    'animation: ${messageAnimation} 0.5s ease-in-out forwards;'}
`;

const SelectOption = styled.div`
  ${textStyles.body.b12sb};
  margin-top: ${dimensions.size16};
  color: ${({ theme }) => theme.colors.text.placeholder};
`;

const ChoicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size8};
  animation: ${choicesAnimation} 1s ease-in-out forwards;
`;

const ChoiceContainer = styled.div`
  ${textStyles.body.b14sb}
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  padding: ${dimensions.size16};
  color: ${({ theme }) => theme.colors.text.header};
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.background.shade};
  border-radius: ${dimensions.size8};

  &:hover {
    background-color: ${({ theme }) => theme.colors.base.light[100]};
  }
`;

const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.text.body};
`;
