import { Button, ButtonGroup } from '@chakra-ui/react';
import {
  ClassDifficultyDiceRollStrategy,
  DataType,
  Dice,
  DiceRollImage,
  DiceRollModifierAction,
  DiceRollStrategy,
  ValueType,
  type DiceRollNodeData,
} from '@common/studio-types';
import {
  Icon,
  NumericInput,
  RadioButtons,
  TextInput,
} from '@maestro/components';
import { Textarea } from '@maestro/components/Textarea';
import { useFeatureFlagEvaluation } from '@maestro/feature-flags';
import { dimensions } from '@maestro/styles';
import styled from 'styled-components';
import { Field } from '../../components/Field';
import { Hint } from '../../components/Hint';
import { useStudioRpgConfig } from '../../hooks/useStudioRpgConfig';
import { StudioNodeFormComponent } from '../Node.types';
import { actions, toDice, strategies } from './diceRoll';
import { ModifierInput } from './ModifierInput';

const maxDice = 3;

export const DiceRollForm: StudioNodeFormComponent<DiceRollNodeData> = ({
  data,
  onChange,
}) => {
  const { rpgConfig } = useStudioRpgConfig();
  const { getFlagEvaluation } = useFeatureFlagEvaluation();
  const showD100 = getFlagEvaluation('ENI-823');
  const dice = toDice(showD100);

  const addDice = (dice: Dice) => () => {
    onChange({ ...data, dices: [...data.dices, dice] });
  };
  const removeDice = (index: number) => () => {
    onChange({
      ...data,
      dices: data.dices.filter((_, i) => i !== index),
    });
  };

  return (
    <Container>
      <Hint>
        Dice roll is the most fun and interactive way to engage your users. This
        is where things get really dynamic.
      </Hint>

      <Field label="Narrator prompt">
        <Textarea
          placeholder="Write a narrator prompt here"
          maxCharacters={100}
          autoFocus={!data.prompt}
          value={data.prompt}
          onChange={(e) => onChange({ ...data, prompt: e.target.value })}
        />
      </Field>

      <Field label="Output name">
        <Hint>The name of the output that will be used in the next nodes.</Hint>
        <TextInput
          maxCharacters={20}
          autoFocus={!data.outputName}
          value={data.outputName}
          onChange={(e) => onChange({ ...data, outputName: e.target.value })}
        />
      </Field>

      <Field label="Dice">
        <Hint>
          Choose the dice you want to roll. For example, 1D20 is different from
          2D10. While 1D20 the range is 1-20, 2D10 the range is 2-20.
        </Hint>
        <DiceContainer>
          {data.dices.map((dice, index) => (
            <img
              src={DiceRollImage[dice]}
              key={index}
              onClick={removeDice(index)}
            />
          ))}
        </DiceContainer>
        <ButtonGroup>
          {dice.map((die) => (
            <Button
              fontSize={dimensions.size12}
              variant="default"
              key={die}
              onClick={addDice(die)}
              isDisabled={data.dices.length >= maxDice}
            >
              {die.toUpperCase()}
            </Button>
          ))}
        </ButtonGroup>
      </Field>

      <Field label="Roll Action">
        <Hint>
          {actions.find(({ value }) => value === data.rollAction)?.description}
        </Hint>
        <RadioButtons
          options={actions}
          value={data.rollAction}
          onChange={(rollAction) => onChange({ ...data, rollAction })}
        />
      </Field>

      <Field label="Strategy">
        <Hint>
          {
            strategies.find(({ value }) => value === data.strategy.type)
              ?.description
          }
        </Hint>
        <RadioButtons
          options={strategies}
          value={data.strategy.type}
          onChange={(strategy) =>
            onChange({
              ...data,
              strategy:
                strategy === DiceRollStrategy.ClassDifficulty
                  ? { type: strategy, classDifficulty: 1 }
                  : { type: strategy },
            })
          }
        />
      </Field>
      {data.strategy.type === DiceRollStrategy.ClassDifficulty && (
        <Field label="Difficulty Class">
          <Hint>
            This is the number that will be used to determine the result of the
            dice roll. If the sum of rolled dice is higher or equal than this
            threshold, it'll be successful and return 1, otherwise 0.
          </Hint>
          <NumericInput
            placeholder="Value"
            value={data.strategy.classDifficulty}
            onChange={(classDifficulty) =>
              onChange({
                ...data,
                strategy: {
                  ...data.strategy,
                  classDifficulty,
                } as ClassDifficultyDiceRollStrategy,
              })
            }
          />
        </Field>
      )}
      <Field label="Modifiers">
        <Hint>
          Modifier makes the dice roll higher or lower depending on certain
          conditions
        </Hint>
        {data.modifiers.map((modifier, index) => (
          <ModifierInput
            key={modifier.id}
            rpgConfig={rpgConfig}
            value={modifier}
            onChange={(value) => {
              const modifiers = [...data.modifiers];
              modifiers[index] = value;
              onChange({ ...data, modifiers });
            }}
            onDelete={() => {
              const modifiers = [...data.modifiers];
              modifiers.splice(index, 1);
              onChange({ ...data, modifiers });
            }}
          />
        ))}
        <Button
          variant="default"
          leftIcon={<Icon name="plus" size={16} />}
          onClick={() => {
            onChange({
              ...data,
              modifiers: [
                ...data.modifiers,
                {
                  id: Math.random().toString(),
                  action: DiceRollModifierAction.Add,
                  value: {
                    type: ValueType.StaticValue,
                    value: 5,
                    dataType: DataType.Number,
                  },
                },
              ],
            });
          }}
        >
          Add modifier
        </Button>
      </Field>
    </Container>
  );
};

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const DiceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  min-height: ${dimensions.size96};
  background-color: ${({ theme }) => theme.colors.background.shade};
  border-radius: ${dimensions.size8};
  padding: ${dimensions.size12} ${dimensions.size8};

  img {
    width: ${dimensions.size72};
    height: ${dimensions.size72};
  }
`;
